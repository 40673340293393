import Button from "@atoms/Button";
import Text from "@atoms/Text";
import texts from '@components/Homepage/en.json';

const ExploreOpportunitiesButton = ({ onButtonClick, className, gtmTag = '' }) => (
    <Button
        design="standard"
        type="primary"
        onClick={() => {
            onButtonClick();
        }}
        cursorClass="cursor-pointer"
        paddingClass='px-6 py-3'
        borderRadiusClass="rounded-full"
        className={`text-center text-basicWhite bg-primary-500 z-40 ${className}`}
        gtmTag={gtmTag}
    >
        <Text
            content={texts?.ExploreOpportunities}
            htmlTag="h3"
            className="p4-semibold text-basicWhite"
        />
    </Button>
);

export default ExploreOpportunitiesButton;