import Text from "@atoms/Text";
import Lottie from "lottie-react";
import diwaliLampAnimation from '../../../public/assets/diwali-lamp-animation.json';
const MuhuratDaysOfferText = () => {
  return ( // #D7BC5F; hardcoded, coz it will not be part of design system
    <div className='bg-festive-gold-gradient px-10 py-3 rounded-full border border-[#D7BC5F] flex gap-2 items-center relative justify-center'>
            <Lottie
                animationData={diwaliLampAnimation} 
                loop={false}
                autoplay={true}
                className="w-11 h-11 absolute left-1 bottom-[1px]"
            />
            <Text content={'<span class="p5-semibold lg:p4-semibold">Festive Season</span> from 1 Nov - 3 Nov, 2024'} className='p5-medium lg:p4-regular text-basicBlack text-center' isInnerHtml />
            <Lottie
                animationData={diwaliLampAnimation}
                loop={true}
                autoplay={true}
                className="w-11 h-11 absolute right-1 bottom-[1px]"
            />
     </div>
    )
}

export default MuhuratDaysOfferText;